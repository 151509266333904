<template>
  <div class="login-form-container">
    <div class="login-form">

      <div class="login-form-image">
        <img src="/images/logo.png">
      </div>

      <div class="login-form-content">
        <form role="form">
          <div class="input-row">
            <label for="login">*Benutzer</label>
            <input class="form-control"
                   id="login"
                   :disabled="loading"
                   v-model="model.username"
            />
          </div>

          <div class="input-row">
            <label for="password">*Passwort</label>
            <input class="form-control"
                   :type="showInput ? 'text' : 'password'"
                   id="password"
                   :disabled="loading"
                   v-model="model.password"
            />
            <a href="#" :class="showInput ? 'password-view view' : 'password-view'" @click="switchPasswordView"></a>
          </div>

          <div class="login-error">
            <p v-if="error">{{errorMessage}}</p>
          </div>

          <div class="form-submit-button">
            <button :disabled="loading" type="submit" class="primary-button" @click="login">
              <span>Anmelden</span>
            </button>
          </div>
        </form>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'Login',
  data() {
    return {
      model: {
        username: '',
        password: ''
      },
      error: false,
      errorMessage: "",
      loading: false,
      showInput: false
    }
  },
  methods: {
    login() {
      this.loading = true;
      const formData = {
        username: this.model.username,
        password: this.model.password
      };
      this.$store.dispatch('signIn', formData).then(() => {
        this.loading = false;
        this.$router.push('/jobs')
      })
          .catch( () => {
            this.loading = false;
            this.error = true;
            this.errorMessage = "Ungültiger login oder passwort";
          });
    },
    switchPasswordView() {
      this.showInput = !this.showInput;
    }
  }
});
</script>

<style lang="scss" scoped>
.login-form-container {
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .login-form {
    max-width: 450px;
    width: 100%;

    .login-form-image {
      text-align: center;
      
      img {
        border-radius: 5px;
      }
    }

    .login-form-content {
      margin-top: 60px;

      .input-row {
        position: relative;
        margin-top: 40px;

        label {
          position: absolute;
          font-weight: 500;
          font-size: 18px;
          color: #022259;
          z-index: 5;
          background: #fff;
          left: 30px;
          top: -10px;
          padding: 0 3px;
          font-style: normal;
        }

        .form-control {
          padding: 20px;
          height: 50px;
          width: 100%;
          font-size: 18px;
          font-weight: 400;
          color: #000000;
          border: 2px solid #022259;
          box-sizing: border-box;
          border-radius: 13px;
          font-family: 'Roboto', sans-serif;
        }

        .password-view {
          position: absolute;
          top: 11px;
          right: 20px;
          display: inline-block;
          width: 35px;
          height: 30px;
          background: url('/images/view.png') 0 0 no-repeat;

          &.view {
            background: url('/images/no-view.png') 0 0 no-repeat;
          }
        }
      }

      .login-error {
        padding: 20px 0;
        text-align: center;
        color: red;
      }

      .form-submit-button {
        text-align: center;

        button {
          margin: 0 auto;
          width: 200px;
          height: 50px;
          background: #022259;
          border-radius: 22px;
          border: #022259;
          font-size: 18px;
          color: #FFFFFF;
        }

        button:active {
          background: #022d79;
          border: #022d79;
        }

        button:hover {
          background: #022d79;
          border: #022d79;
        }
      }
    }
  }
}
</style>
