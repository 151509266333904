<template>
  <div class="body-container">
    <header>
      <div class="links">
        <img src="/images/logo_admin.png" class="logo-image"/>
        <a class="logout" href="#" @click="logout"><img src="/images/logout.png"/></a>
      </div>
    </header>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'Admin',
  methods: {
    logout() {
      this.$store.dispatch('logOut').then(() => {
        this.$router.push('/')
      });
    }
  }
});
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  background: #022259;
  z-index: 9;
}

.body-container {
  font-family: "Roboto", sans-serif;

  .links {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    width: 100%;

    .logo-image {
      height: 62px;
      width: 132px;
      border-radius: 5px;
      background: #ffffff;
    }

    .logout {
    
      img {
        width: 34px;
      }
    }

    .logout:hover {
      box-shadow: 0 0 15px #0348c0;
    }

  }
}
@media screen and (min-width: 1200px) and (max-width: 1920px){
  .logo-image {
    margin-left: 45px;
  }

  .logout {
    margin-right: 45px;
  }
}

@media screen and (max-width: 1400px){
  .logo-image {
    margin-left: 45px;
  }

  .logout {
    margin-right: 45px;
  }

}

@media screen and (min-width: 1920px){
  .links {
    width: 1920px;
    margin: 0 auto;
  }

  .logo-image {
    margin-left: 45px;
  }

  .logout {
    margin-right: 45px;
  }
}
</style>
<style lang="scss">
a {
  &:hover {
    cursor: pointer;
  }
}
</style>
